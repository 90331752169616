import SwiperCore, {
    A11y, Autoplay,
    Lazy,
    Navigation, Pagination, Scrollbar,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';
import Html from 'Component/Html';
import Link from 'Component/Link';
import {
    SliderWidgetComponent as SourceSliderWidget,
} from 'SourceComponent/SliderWidget/SliderWidget.component';

import './SliderWidget.override.style';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay, Lazy]);

/** @namespace Pwa/Component/SliderWidget/Component */
export class SliderWidgetComponent extends SourceSliderWidget {
    state = {
        activeImage: 0,
        carouselDirection: Directions.RIGHT,
    };

    renderSlide = (slide, i) => {
        const {
            slide_text,
            slide_link,
            title: block,
        } = slide;

        const imageSrc = this.getSlideImage(slide);

        return (
            <SwiperSlide key={ block }>
                <figure
                  block="SliderWidget"
                  elem="Figure"
                  key={ i }
                >
                    <Link to={ slide_link || '/' }>
                        <img
                          key={ imageSrc }
                          className="swiper-lazy"
                          mix={ { block: 'SliderWidget', elem: 'FigureImage' } }
                          alt=""
                          data-src={ imageSrc }
                        />
                    </Link>
                </figure>
                { slide_text && <Html content={ slide_text } /> }
            </SwiperSlide>
        );
    };

    onActiveImageChange(activeImage) {
        this.setState({ activeImage });
        this.changeDirection(activeImage);
    }

    changeDirection(activeImage) {
        const { slider: { slides } } = this.props;

        if (activeImage === 0) {
            this.setState({ carouselDirection: Directions.RIGHT });
        }

        if (activeImage === (slides?.length || 0) - 1) {
            this.setState({ carouselDirection: Directions.LEFT });
        }
    }

    render() {
        const { slider: { slides, slide_speed } } = this.props;

        if (!slide_speed || !slides.length) { // FIX MCT-1295
            return null;
        }

        const isSlider = (slides.length > 1);
        const autoplayConfig = isSlider ? { delay: slide_speed } : false;

        const navigationConfig = isSlider ? {
            nextEl: '.ProductSlider-Next',
            prevEl: '.ProductSlider-Prev',
            disabledClass: 'ProductSlider-Off',
        } : false;

        return (
            <div className="HomepageSliderWidget">
                <Swiper
                  preloadImages
                  lazy={ {
                      loadPrevNext: true,
                      loadPrevNextAmount: 1,
                  } }
                  navigation={ navigationConfig }
                  pagination={ { clickable: true } }
                  autoplay={ autoplayConfig } // eslint-disable-line no-magic-numbers
                  watchOverflow
                  loop={ (slides.length > 1) }
                  scrollbar={ { draggable: true } }
                  onSlideChange={ this.onActiveImageChange }
                >
                    { slides.map(this.renderSlide) }

                    { isSlider && (
                        <div
                          block="ProductSlider"
                          elem="Next"
                          onClick={ () => {
                              window?.dataLayer?.push({
                                  event: 'click_change_slider',
                                  category: 'click_change_slider',
                                  action: 'slider_homepage',
                                  label: 'forward',
                              });
                          } }
                          mods={ { type: 'reel' } }
                        />
                    ) }
                    { isSlider && (
                        <div
                          block="ProductSlider"
                          elem="Prev"
                          onClick={ () => {
                              window?.dataLayer?.push({
                                  event: 'click_change_slider',
                                  category: 'click_change_slider',
                                  action: 'slider_homepage',
                                  label: 'backward',
                              });
                          } }
                          mods={ { type: 'reel' } }
                        />
                    ) }
                </Swiper>
            </div>
        );
    }
}

export default SliderWidgetComponent;
